



<template>
  <div class="map" ref="map"></div>
</template>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsBoolean, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, onMounted, Ref, ref } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as gmap from '@fwk-client/utils/gmap';
import { getPath } from '@igotweb-node-api-utils/misc';

export interface Point {
  lat:number;
  lng:number;
}

/** @cmsOptions */
interface MapOptions {
  /** @cmsType CmsInterface */
  center?:Point;
  /** @cmsType CmsInterface */
  marker?:Point;
}

/** @cmsLabels */
interface MapLabels {
  /** @cmsType CmsLabel */
  marker?:CmsLabel;
}

/** @cmsSlots */
export interface MapSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<MapOptions>,
        labels: {
          type: Object as PropType<MapLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<MapSlots>
    },
    components : {
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const map:Ref<HTMLElement|null> = ref(null);

        const computedOptions:MapOptions = {
            ...props.options
        };

        const computedLabels:MapLabels = {
            ...props.labels
        }

        onMounted(() => {
          if(computedOptions.center) {
            showMap();
          }
        })

        const showMap = () => {
          // We need to get the apiKey
          var apiKey = getPath($store.state, "server.configuration.fwk.map.gmap.id");
          // We create the map
          gmap.createMap(map.value as HTMLElement, apiKey, {
            mapTypeId: "roadmap",
            zoom: 17,
            center: computedOptions.center,
            styles : [
              // We hide other business point of interests
              {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              }
            ],
          })
          .then((map) => {
            if(computedOptions.marker) {
              var marker = new google.maps.Marker({
                position: computedOptions.marker,
                label : {
                  text:app.getLocalizedText(computedLabels.marker)
                },
                animation: google.maps.Animation.DROP,
                map: map,
                icon : {
                  url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
                  size: new google.maps.Size(27, 43),
                  scaledSize: new google.maps.Size(27, 43),
                  anchor:new google.maps.Point(14, 43),
                  labelOrigin: new google.maps.Point(14, 43+10) // Label origin is center of the text
                }
              })

              var info = new google.maps.InfoWindow({
                content: '<div style="width: 300px;"><h4 style="margin-bottom: 8px;">Hi, we\'re <span>Envato</span></h4><p class="mb-0">Our mission is to help people to <strong>earn</strong> and to <strong>learn</strong> online. We operate <strong>marketplaces</strong> where hundreds of thousands of people buy and sell digital goods every day, and a network of educational blogs where millions learn <strong>creative skills</strong>.</p></div>',
                position: computedOptions.marker,
              })
              marker.addListener('click', function() {
                console.log("gmap marker click")
                // info.open(map);
              })
            }
          })
          .catch((error) => {
            console.log(error);
          });
        }

        return {
          map,
            computedOptions: {
                ...computedOptions
            },
            labels:computedLabels
        }

    }
})
</script>